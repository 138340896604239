import "./DateRangePickerModal.scss"
import "react-date-range/dist/theme/default.css"
import "react-date-range/dist/styles.css"
import { useEffect, useRef, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { DateRange } from "react-date-range"
import { startOfDay, endOfDay, startOfSecond, endOfSecond, addDays } from "date-fns"
import { buildFromPreset, DefaultPresetRanges } from "./Presets"
import { useSelector, useDispatch } from "react-redux"
import { updateDateRange } from "../../redux/dateRangeSlice"
import { convertDateToTime, convertTimeToValidDate, hourMinute, isValidTime, validateDate, validateDateTime, validateTime } from "../../utils/time"
import { useHistory } from "react-router-dom"
import ReactDatePicker from "react-datepicker"

/**
 * @param {[boolean, function(boolean)]} show The state for the visibility of the modal.
 * @param {DateRange|boolean|null} value The current value, null to get from the global value, or false for no selection.
 * @param {function|null} update If set this function will be call instead of setting the global change.
 */
export const DateRangePickerModal = ({ show, value = null, update = null }) => {
  const dateRangeRedux = useSelector((state) => state.dateRange)
  const dispatch = useDispatch()
  const { location } = useHistory()
  const [isVisible: boolean, setIsVisible] = show

  // Use custom range or from the redux store
  const dateRange = value === false ? null : (value ?? dateRangeRedux)
  console.log(dateRange)
  // Local states
  const [localDateRange, setLocalDateRange] = useState(dateRange)
  const [timeRangeStart, setTimeRangeStart] = useState(hourMinute(dateRange))
  const [timeRangeEnd, setTimeRangeEnd] = useState(hourMinute(dateRange))
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState()
  const [presets, setPresets] = useState(DefaultPresetRanges().map((item) => buildFromPreset(item)))

  // Update the global or local time
  const setDateRange = (dateRange) => {
    update
      ? update(dateRange)
      : dispatch(updateDateRange(dateRange))
  }

  /**
   * Convert the date range from the standardized one to the one used by DateRange component.
   *
   * @param {DateRangeDTO} dateRange The standardized data range used in our app.
   *
   * @return {DateRangeOriginalDTO[]} The date range as used by the DateRange component.
   */
  const fromStandard = (dateRange) => [
    {
      key: "selection",
      startDate: dateRange?.start,
      endDate: dateRange?.end
    }
  ]

  /**
   * Apply the selected range and hide the modal.
   */
  const applyRange = () => {

    // Start time
    const [startHours, startMinutes] = timeRangeStart.split(":")
    localDateRange.start.setHours(startHours)
    localDateRange.start.setMinutes(startMinutes)

    // End time
    const [endHours, endMinutes] = timeRangeEnd.split(":")
    localDateRange.end.setHours(endHours)
    localDateRange.end.setMinutes(endMinutes)

    applyAndHide(localDateRange)
  }

  /**
   * Apply a preset as the selected range and hide the modal.
   *
   * @param {DateRangePresetDTO} preset The preset to apply.
   */
  const applyPreset = (preset) => {
    const start = preset.name === "Last 1 hour" ? preset.start
      : preset.name === 'Today' ? startOfDay(new Date())
        : preset.name === 'Yesterday' ? addDays(startOfDay(new Date()), -1)
          : startOfSecond(preset.start)

    const end = preset.name === "Last 1 hour" ? preset.end
      : preset.name === 'Today' ? endOfDay(new Date())
        : preset.name === 'Yesterday' ? addDays(endOfDay(new Date()), -1)
          : endOfSecond(preset.end)
    applyAndHide({ name: preset.name, start: validateDate(start), end: validateDate(end) })
  }

  /**
   * Apply the date range and hide the modal.
   *
   * @param {DateRangePresetDTO} dateRange The date range to apply.
   */
  const applyAndHide = (dateRange: DateRangePresetDTO) => {
    setLocalDateRange(dateRange)
    setDateRange(dateRange)
    setIsVisible(false)
  }

  // Define an input for the time
  const timeInput = (time, setTime, type) => (
    <div className="form-group">
      {/* <input
        type="time"
        className="form-control"
        step="900"
        value={time}
        onChange={event => {
          console.log(event.target.value)
          setTime(validateTime(event.target.value))
        }}
      /> */}
      <ReactDatePicker
        selected={type === 'start' ? validateDateTime(localDateRange?.start) : validateDateTime(localDateRange?.end)}
        onChange={(date) => {
          if (type === 'start') {
            setStartDate(date)
            setLocalDateRange({
              ...localDateRange,
              start: date
            })
          }
          else if (type === 'end') {
            setEndDate(date)
            setLocalDateRange({
              ...localDateRange,
              end: date
            })
          }
          setTime(validateTime(convertDateToTime(date)))
        }}
        className="form-control"
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="HH:mm"
      />
    </div>
  )

  useEffect(() => {

    if (isValidTime(timeRangeStart, timeRangeEnd)) {
      const inCorrectTime = timeRangeEnd <= timeRangeStart;
      const inCorrectDate = new Date(localDateRange.end) <= new Date(localDateRange.start)

      if (inCorrectTime && inCorrectDate) {
        setDisabled(true)
      }
      else {
        setDisabled(false)
      }
    }
    else {
      setDisabled(true)
    }

  }, [timeRangeEnd, timeRangeStart, localDateRange])

  // console.log({ timeRangeStart, timeRangeEnd, localDateRange, startDate, endDate, formattedStartDate: validateDateTime(localDateRange?.start) })

  return (
    <Modal centered show={isVisible} size="lg" onHide={setIsVisible}>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">

            {/* The list of preset date ranges */}
            <div className="preset-dates">
              {presets.map((preset) => {
                return (
                  <div key={preset.name}>
                    <Button variant="secondary" className="full-width mb-5" onClick={() => applyPreset(preset)}>
                      {preset.name}
                    </Button>
                  </div>
                )
              })}
            </div>
          </div>

          {/* The range picker */}
          <div className="col-md-6">

            {/* Date range */}
            <DateRange
              onChange={(item) => {
                setDisabled(false)
                // Reset the time
                setTimeRangeStart(validateTime("00:00"))
                setTimeRangeEnd(validateTime("23:59"))

                // Update the range
                setLocalDateRange({
                  start: item.selection.startDate,
                  end: endOfSecond(item.selection.endDate)
                })
              }}
              ranges={fromStandard(localDateRange)}
              maxDate={new Date()}
            />
            {error && <div className="error">{error}</div>}

            {/* Time range */}
            <div className="row mt-5">
              <div className="col-5">
                {timeInput(timeRangeStart, setTimeRangeStart, 'start')}


              </div>
              <div className="col-1" style={{ lineHeight: "3em" }}> -</div>
              <div className="col-5">
                {timeInput(timeRangeEnd, setTimeRangeEnd, 'end')}

              </div>

            </div>

          </div>
        </div>

        {/* Control buttons */}
        <div className="text-right">
          <Button variant="secondary" className="mr-3" onClick={() => setIsVisible(false)}>
            Close
          </Button>
          <Button disabled={disabled} variant="primary" onClick={() => applyRange()}>
            Apply
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
