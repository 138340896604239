import { LoadingWrapper } from "components";
import { useLoading } from "hooks/useLoading";
import React from "react";
import { useSelector } from "react-redux";
import useAPI from "services/ApiService";
import TrafficFlowDiagram from "./TrafficDiagram";
import CountDiagram from "./CountDiagram";

const TrafficDiagramWrapper = ({ camera }) => {
  const api = useAPI();
  const [systemID, cameraID] = [camera?.sys_id, camera?.camera_id];
  const dateRange = useSelector((state: ReduxStore) => state.dateRange);
  const [report, reportLoadingState] = useLoading(() => api.getOriginDestinationReport(systemID, cameraID, dateRange), []);
  // console.log(report);
  return (
    <LoadingWrapper state={reportLoadingState}>
      {/* <TrafficFlowDiagram data={report} /> */}
      <CountDiagram data={report} />
    </LoadingWrapper>
  );
};

export default TrafficDiagramWrapper;
